import React from "react";

const FullCase = ({ image }) => {
  // const { img, id } = image;
  // console.log("this is", img);
  return (
    <div>
      {/* <h1>{id}</h1> */}
      {/* <img src={imgs} alt="lksd" /> */}
    </div>
  );
};

export default FullCase;
