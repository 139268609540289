import { Link } from "react-router-dom";
import "./Case.css";

import preview1 from "./CaseImages/Preview-1.png";
import preview2 from "./CaseImages/Preview-2.png";
import preview3 from "./CaseImages/Preview-3.jpg";
import preview4 from "./CaseImages/Preview-4.jpg";
import preview5 from "./CaseImages/Preview-5.jpg";
import preview6 from "./CaseImages/Preview-6.jpg";
import preview7 from "./CaseImages/Preview-7.jpg";
import preview8 from "./CaseImages/Preview-8.jpg";
import preview9 from "./CaseImages/Preview-9.jpg";
import preview10 from "./CaseImages/Preview-10.jpg";
import preview11 from "./CaseImages/Preview-11.jpg";
import preview12 from "./CaseImages/Preview-12.jpg";

const Case = () => {
  return (
    <div>
      <h1 className="case_title">Watch our Favourite Case Studies </h1>
      <div className="case">
        <Link to={"/prolific"} className="card">
          <img width={"300px"} src={preview1} />
        </Link>
        <Link to={"/compmedia"} className="card">
          <img width={"300px"} src={preview2} />
        </Link>
        <Link to={"/haydrosincs"} className="card">
          <img width={"300px"} src={preview3} />
        </Link>
        <Link to={"/created"} className="card">
          <img width={"300px"} src={preview4} />
        </Link>
        <Link to={"/hero"} className="card">
          <img width={"300px"} src={preview5} />
        </Link>
        <Link to={"/cloudfz"} className="card">
          <img width={"300px"} src={preview6} />
        </Link>
        <Link to={"/guancaste"} className="card">
          <img width={"300px"} src={preview7} />
        </Link>
        <Link to={"/flixit"} className="card">
          <img width={"300px"} src={preview8} />
        </Link>
        <Link to={"/toner"} className="card">
          <img width={"300px"} src={preview9} />
        </Link>
        <Link to={"/vision"} className="card">
          <img width={"300px"} src={preview10} />
        </Link>
        <Link to={"/movers"} className="card">
          <img width={"300px"} src={preview11} />
        </Link>
        <Link to={"/family"} className="card">
          <img width={"300px"} src={preview12} />
        </Link>
      </div>
    </div>
  );
};

export default Case;
