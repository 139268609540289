import React from "react";
import { Link } from "react-router-dom";
import './Homepage.css'
import logo from './logo.png'
const CaseNav = () => {
    return(
        <div>
          {/* This is Navbar */}
          <div className="home_nav">
            <div className="logo_div">
              <img className="logo" src={logo} alt="fighter3 logo" />
            </div>
            <div>
              <Link
                className="order_now_sm"
                to="/"
              >
                Back To Home
              </Link>
            </div>
          </div>
          <br/>
          <br/>
        </div>
    );
}

export default CaseNav;