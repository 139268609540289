import React from "react";
import "./Faq.css";

const Faq = () => {
  return (
    <div className="faq_div">
      <h1 className="faq_title">Frequently Asked Questions</h1>
      <div className="faq_question_div">
        <div className="faq_question">
          <h2>What if I don't like designs?</h2>
          <h5 style={{ fontWeight: "500" }}>
            If you are not satisfied at all with the logo, based on the
            circumstances, most of the time we do offer refunds.
            Mutual-cancelation/refunds are only available before the order is
            rated/marked as complete by you. Refunds are not available once the
            process of revision starts on the logo chosen.
          </h5>
        </div>

        <div className="faq_question">
          <h2>What is the current turnaround?</h2>
          <h5 style={{ fontWeight: "500" }}>
            Oour current turnaround is 3 days. If you need an urgent 24 hours
            delivery, you can always purches additional.
          </h5>
        </div>
      </div>

      <div className="second_faq">
        <div className="faq_question">
          <h2 className="what">What if I don't like designs?</h2>
          <h5 style={{ fontWeight: "500" }}>
            If you are not satisfied at all with the logo, based on the
            circumstances, most of the time we do offer refunds.
            Mutual-cancelation/refunds are only available before the order is
            rated/marked as complete by you. Refunds are not available once the
            process of revision starts on the logo chosen.
          </h5>
        </div>
        <br />
        <br />
        <div className="faq_question">
          <h2 className="what">What is the current turnaround?</h2>
          <h5 style={{ fontWeight: "500" }}>
            Oour current turnaround is 3 days. If you need an urgent 24 hours
            delivery, you can always purches additional.
          </h5>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="team">
        <h1 className="faq_title1">Our Team is Your Team</h1>
        <h3 className="faq_bio">
          Do you need top-quality loto design services at an affordable price?
          Look no further than our in-house team and production sutdio! We
          create custom logos quickly and affordably without compromising
          quality. Our creative team is passionate about putting clients first
          and bringing their vision to life.
        </h3>
      </div>
      <br />
      <br />
      <br />
      <div className="get_start">
        <h1 className="get_start_title">Ready to Get Started</h1>
        <h3 className="get_start_bio">
          Enhance your brand with a proven logo desing serviec. Take advantage
          of our <br /> expertise to drive more results and capture more
          audiences!
        </h3>
        <div className="quick_order">
          <a
            className="quick_order_btn"
            href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
          >
            ORDER NOW
          </a>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Faq;
