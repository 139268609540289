import React from "react";
import "./Minimalist.css";
import gig1 from "./gig1.jpeg";
import gig2 from "./gig2.jpeg";

const Minimalist = () => {
  return (
    <>
      <p className="minimalist_title">
        Logo Design or Update For Your Business In Any Stage.
      </p>
      <div className="minimalist">
        <div className="mini_left">
          <h2 className="mini_title">
            We will create edit or update <br /> your logo design
          </h2>
          <h2 className="mini_title2">
            We will create edit or update <br /> your logo design
          </h2>
          <p className="mini_des">
            Logo redesigning without a strategy is like a doctor operating a
            patient without diagnosing. If you don't want to be treated by an
            unprofessional doctor, why would you want to redesign your logo
            without a strategy?
          </p>
          <br />
          <div className="order_md">
            <a
              style={{ justifyContent: "center" }}
              className="order_now_md"
              href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3%2Fredesign-your-existing-logo"
            >
              ORDER NOW
            </a>
          </div>
        </div>
        <div>
          <img className="gig1" src={gig1} alt="gig1.jpeg" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="minimalist2">
        <div className="mini_left">
          <h2 className="mini_title">
            We will modern minimalist and <br /> custom logo design
          </h2>
          <h2 className="mini_title2">
            We will modern minimalist and custom logo design
          </h2>

          <p className="mini_des">
            We stand out from our competition due to our best-in-class Customer
            Support and Quality Designs.
          </p>
          <br />
          <div className="order_md">
            <a
              style={{ justifyContent: "center" }}
              className="order_now_md"
              href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3%2Fdo-design-all-kind-of-logo-for-your-business-and-product"
            >
              ORDER NOW
            </a>
          </div>
        </div>
        <div>
          <img className="gig1" src={gig2} alt="gig2.jpeg" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default Minimalist;
