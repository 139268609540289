import React from "react";
import "./Review.css";

const QuickDesign = (props) => {
  const { img, title, description } = props.designs;
  return (
    <div className="quick_custom_logos">
      <div className="quick_head">
        <img className="quick_icons" src={img} alt="icons" />
        <h2>{title}</h2>
      </div>
      <p className="quick_description">{description}</p>
    </div>
  );
};

export default QuickDesign;
