import { RouterProvider } from "react-router-dom";
import "./App.css";
import router from "./Routes/Routes";

function App() {
  const disableRightClick = (e) => {
    e.preventDefault();
  };
  return (
    <div onContextMenu={disableRightClick}>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
