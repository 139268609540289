import React from "react";
import { Link } from "react-router-dom";
import CaseNav from "../HomePage/CaseNav";
import "./Prolific.css";
import design from "./CaseImages/design-5.jpg";
import design4 from "./CaseImages/Preview-4.jpg";
import design6 from "./CaseImages/Preview-6.jpg";
import design7 from "./CaseImages/Preview-7.jpg";
import design8 from "./CaseImages/Preview-8.jpg";
import design9 from "./CaseImages/Preview-9.jpg";
import design10 from "./CaseImages/Preview-10.jpg";
const Hero = () => {
  return (
    <div>
      <CaseNav />
      <h1 className="case_name">Gift A Hero</h1>
      <img className="cus_img" src={design} alt="Prolific" /> <br />
      <div className="case_bottom_section">
        <h2>These Also</h2>

        <Link className="case_bottom_link" to="/created">
          <img src={design4} alt="this" />
        </Link>

        <Link className="case_bottom_link" to="/cloudfz">
          <img src={design6} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/guancaste">
          <img src={design7} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/flixit">
          <img src={design8} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/toner">
          <img src={design9} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/vision">
          <img src={design10} alt="this" />
        </Link>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Hero;
