import React, { Component } from "react";
import "./Homepage.css";
import logo from "./logo.png";
import video from "./video.mp4";
import { Link } from "react-router-dom";
export default class Homepage extends Component {
  render() {
    return (
      <>
        <div>
          {/* This is Navbar */}
          <div className="home_nav">
            <div className="logo_div">
              <Link to="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3">
                <img className="logo" src={logo} alt="fighter3 logo" />
              </Link>
            </div>
            <div>
              <a
                className="order_now_sm"
                href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
              >
                ORDER NOW
              </a>
            </div>
          </div>

          {/* This is Home Banner */}
          <div className="home_banner">
            <h1 className="home_banner_title">
              create a modern minimalist and <br /> custom logo design for
              <br /> your business.
            </h1>
            <p className="home_banner_bio">
              Order our custom logo gig to boost your business and <br /> win
              more clients.
            </p>
            <div className="banner_order">
              <a
                className="order_now_sm"
                href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
              >
                ORDER NOW
              </a>
              <a
                className="see_our_work_md"
                href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
              >
                SEE OUR WORK
              </a>
            </div>
            <div className="affordable">
              <div>
                <video
                  className="affordable_img"
                  autoPlay
                  loop
                  muted
                  height="240"
                >
                  <source src={video} type="video/mp4" />
                </video>
              </div>
              <div className="affordable_details">
                <h2 className="affordable_title">
                  finding an affordable and high
                  <br />
                  quality logo design gig can <br /> be challenging.
                </h2>
                <p className="affordable_bio">
                  Fiverr seller Fighter3 delivers affordable, high-quality logo
                  design services. We provide customised scalable formats,
                  timely delivery, and open communication in an affordable
                  price.
                </p>
                <br />
                <a
                  style={{ justifyContent: "center" }}
                  className="order_now_md"
                  href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
                >
                  ORDER NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
