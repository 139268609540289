import React, { useEffect, useState } from "react";
import "./Review.css";
import reviewImg from "./review_img.png";
import QuickDesign from "./QuickDesign";

const Review = () => {
  const [design, setDesign] = useState([]);
  useEffect(() => {
    fetch("./designs.json")
      .then((res) => res.json())
      .then((data) => setDesign(data));
  }, []);

  return (
    <>
      <div className="review">
        <div className="info">
          <div className="logos_made">
            <h1 className="logos_count">373</h1>
            <p style={{ marginTop: "-15px", fontWeight: "bold" }}>LOGOS MADE</p>
          </div>
          <div className="logos_made">
            <h1 className="logos_count">278</h1>
            <p style={{ marginTop: "-15px", fontWeight: "bold" }}>Reviews</p>
          </div>
          <div className="logos_made">
            <h1 className="logos_count">25%</h1>
            <p style={{ marginTop: "-15px", fontWeight: "bold" }}>
              INCREASED SALE
            </p>
          </div>
          <div className="logos_made">
            <h1 className="logos_count">1</h1>
            <p style={{ marginTop: "-15px", fontWeight: "bold" }}>
              DEDICATIVE TEAM
            </p>
          </div>
        </div>
        <div className="rely">
          <div>
            <img
              className="review_img"
              src={reviewImg}
              alt="This is a review img"
            />
          </div>
          <div>
            <div className="rely_info">
              <h1 className="rely_title">Do not just rely on our word</h1>
              <p className="affordable_bio">
                Find out why our clients love our custom logo design service.
              </p>
              <p className="affordable_bio">
                Take your brand to the next level with our recent reviews!
              </p>
              <br />
              <div className="order_md">
                <a
                  style={{ justifyContent: "center" }}
                  className="order_now_md"
                  href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
                >
                  ORDER NOW
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="quick_logo" style={{ paddingTop: "70px" }}>
          <h2 className="quick_title">Quick and Custom Logo Design</h2>
          <p className="quick_bio">
            To create a unique and recognizable identity for a product or
            business we follow a custom design process.
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className="quick_design">
        {design.map((quickDesign) => (
          <QuickDesign key={quickDesign.id} designs={quickDesign}></QuickDesign>
        ))}
      </div>
      <br />
      <br />
      <div className="quick_order">
        <a
          className="quick_order_btn"
          href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
        >
          ORDER NOW
        </a>
      </div>
    </>
  );
};

export default Review;
