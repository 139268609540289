import React from "react";
import { Link } from "react-router-dom";
import CaseNav from "../HomePage/CaseNav";
import "./Prolific.css";
import design from "./CaseImages/design-3.jpg";
import design1 from "./CaseImages/Preview-1.png";
import design8 from "./CaseImages/Preview-8.jpg";
import design4 from "./CaseImages/Preview-4.jpg";
import design5 from "./CaseImages/Preview-5.jpg";
import design6 from "./CaseImages/Preview-6.jpg";
import design7 from "./CaseImages/Preview-7.jpg";
const Haydrosincs = () => {
  return (
    <div>
      <CaseNav />
      <h1 className="case_name">Hydrosnics</h1>
      <img className="cus_img" src={design} alt="haydrosincs" /> <br />
      <div className="case_bottom_section">
        <h2>These Also</h2>
        <Link className="case_bottom_link" to="/prolific">
          <img src={design1} alt="this" />
        </Link>

        <Link className="case_bottom_link" to="/created">
          <img src={design4} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/hero">
          <img src={design5} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/cloudfz">
          <img src={design6} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/guancaste">
          <img src={design7} alt="this" />
        </Link>
        <Link className="case_bottom_link" to="/flixit">
          <img src={design8} alt="this" />
        </Link>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Haydrosincs;
