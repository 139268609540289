import React from "react";
import logo from "./logo.png";
import "./Fotter.css";
import { Link } from "react-router-dom";
const Fotter = () => {
  return (
    <>
      <div className="fotter">
        <div>
          <Link to="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3">
            <img width={"170px"} src={logo} alt="footer logo" />
          </Link>
        </div>
        <div>
          <h3 className="fotter_bio">
            At this level, we would charge a great deal for high-qualtiy logos,
            but we offer them for a fraction of the price on Fiverr.
          </h3>
        </div>
        <div className="fotter_btn">
          <a
            className="order_now_sm"
            href="https://go.fiverr.com/visit/?bta=325066&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffighter3"
          >
            ORDER NOW
          </a>
        </div>
      </div>
      <br />
      <br />
      <h3
        style={{
          fontSize: "14px",
          color: "#6e7170",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        All Fiverr logos and name is property of Fiverr International Limited I
        accessibility
      </h3>
    </>
  );
};

export default Fotter;
