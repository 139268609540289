import React from "react";
import "./Main.css";
import Homepage from "../Components/HomePage/Homepage";
import Review from "../Components/Review/Review";
import Faq from "../Components/Faq/Faq";
import Fotter from "../Components/Fotter/Fotter";
import Case from "../Components/Case/Case";
import Minimalist from "../Components/Minimalist/Minimalist";

const Main = () => {
  const disableRightClick = (e) => {
    e.preventDefault();
  };
  return (
    <div onContextMenu={disableRightClick}>
      <Homepage />
      <Case />
      <Review />
      <Minimalist />
      <Faq />
      <Fotter />
    </div>
  );
};

export default Main;
