import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";

import Prolific from "../Components/Case/Prolific";
import Compmedia from "../Components/Case/Compmedia";
import FullCase from "../Components/Case/FullCase";

import Created from "../Components/Case/Created";
import Hero from "../Components/Case/Hero";
import Cloudfz from "../Components/Case/Cloudfz";
import Guancaste from "../Components/Case/Guancaste";
import Flixit from "../Components/Case/Flixit";
import Toner from "../Components/Case/Toner";
import Vision from "../Components/Case/Vision";
import Family from "../Components/Case/Family";
import Haydrosincs from "../Components/Case/Haydrosincs";
import Movers from "../Components/Case/Movers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/finder_case",
    element: <FullCase />,
  },
  {
    path: "/haydrosincs",
    element: <Haydrosincs />,
  },
  {
    path: "/created",
    element: <Created />,
  },
  {
    path: "/hero",
    element: <Hero />,
  },
  {
    path: "/cloudfz",
    element: <Cloudfz />,
  },
  {
    path: "/prolific",
    element: <Prolific />,
  },
  {
    path: "/compmedia",
    element: <Compmedia />,
  },
  {
    path: "/guancaste",
    element: <Guancaste />,
  },
  {
    path: "/flixit",
    element: <Flixit />,
  },
  {
    path: "/toner",
    element: <Toner />,
  },
  {
    path: "/vision",
    element: <Vision />,
  },
  {
    path: "/movers",
    element: <Movers />,
  },
  {
    path: "/family",
    element: <Family />,
  },
]);

export default router;
